
import { defineComponent } from 'vue'
export default defineComponent({
    props: ['dataTitle', 'list', 'width'],
    methods: {
        close () {
            this.$emit('close')
        }
    }
})
