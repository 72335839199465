
// TODO 多选在分页时存在问题
import { defineComponent } from 'vue'
import alertBox from '@/components/alert-box.vue'
import { getUserCompanyList, getUserToken } from '@/api/base'
import $store from '@/store'
import { wait } from '@/utils/util'

export default defineComponent({
    computed: {
        $store () {
            return $store
        }
    },
    props: ['clientName'],
    components: {
        'alert-box': alertBox
    },
    async created () {
        this.switchAllList = await getUserCompanyList()
        this.search()
    },
    data () {
        return {
            searchText: '',
            switchList: [] as {
        client_name: string
        client_user_id: number
        client_create_time: string
      }[],
            switchAllList: [] as {
        client_name: string
        client_user_id: number
        client_create_time: string
      }[]
        }
    },

    methods: {
        search () {
            this.switchList = this.switchAllList.filter(item => item.client_name.includes(this.searchText))
        },
        async checkUser (clientUserId: number) {
            this.$emit('close')
            const { token } = await getUserToken({
                clientUserId: clientUserId
            })
            this.$message.success('正在为您切换，请稍后...')
            await wait(600)
            localStorage.tokenC = token
            window.location.reload()
        },
        close () {
            this.$emit('close')
        }

    }
})
