
import { defineComponent } from 'vue'
import containerNav from '@/components/container-nav.vue'
import containerHeader from '@/components/container-header.vue'
import { RouteMeta, _RouteRecordBase } from 'vue-router'
export default defineComponent({
    name: 'Index',
    components: { containerNav, containerHeader },
    watch: {
        $route (to: _RouteRecordBase) {
            const name = to.name as string
            const meta = to.meta as RouteMeta
            if (meta.keepAlive && this.includeList.indexOf(name) === -1) {
                this.includeList.push(name)
            }
            console.log(this.includeList)
        }
    },
    data () {
        return {
            includeList: [] as string[]
        }
    }
})
