import { createStore } from 'vuex'
export default createStore({
    state: {
        collapse: false,
        titles: [],
        userName: '',
        companyName: '',
        avatar: '',
        project: {
            name: '',
            logo: '',
            logo_square: '',
            mp_qrcode: ''
        }
    },
    mutations: {
        setCollapse (state, status: boolean): void {
            state.collapse = status
        },
        setTitles (state, titles: []): void {
            state.titles = titles
        },
        setUser (state, data) {
            state.userName = data.userName
            state.avatar = data.avatar
            state.companyName = data.companyName
        },
        setProject (state, data) {
            state.project = data
        }
    },
    actions: {
    }
})
