import {
    login,
    getUserInfo,
    GetUserInfoResDto,
    getSms,
    getUserCompany
} from '@/api/base'
import global from './global'
import store from '@/store'
import router from '@/router'

export interface UserDto {
  userId: string
  userName: string
  nickName: string
  phone: string
  sex: '0' | '1'
  avatar: string
  companyId: string
  isAdmin: number
  isWaiting: boolean
}

class User {
  userId = 0
  userName = ''
  phone = ''
  avatar = ''
  isAdmin = 0
  isWaiting = ''
  companyName = ''
  miniprogQrcode = ''

  waitLogin (): Promise<number> {
      return new Promise(resolve => {
          setInterval(() => {
              if (this.isWaiting) {
                  resolve(this.userId)
              }
          }, 300)
      })
  }

  // 获取短信
  getSms (phone: string): Promise<void> {
      return getSms({
          mobile: phone
      })
  }

  exit () {
      this.userId = 0
      global.token = ''
      localStorage.removeItem('tokenC')
      router.push({
          path: '/login'
      })
  }

  async login (data: {
    mobile?: string
    smsCode?: string
    username?: string
    password?: string
    loginMode: string
  }): Promise<GetUserInfoResDto> {
      const result = await login(data)
      global.token = result.token
      localStorage.tokenC = result.token
      return this.getUserInfo()
  }

  async getUserInfo () {
      const result = await Promise.all([getUserInfo(), getUserCompany()])
      this.userId = result[0].userInfo.client_user_id
      this.phone = result[0].userInfo.mobile
      this.userName = result[0].userInfo.real_name
      this.isAdmin = result[0].userInfo.is_super
      this.avatar = result[0].userInfo.avatar
      this.companyName = result[1].clientInfo.name
      this.miniprogQrcode = result[1].clientInfo.project.mp_qrcode
      this.isWaiting = '1'
      store.commit('setUser', {
          avatar: result[0].userInfo.avatar,
          userName: result[0].userInfo.real_name,
          companyName: result[1].clientInfo.name
      })
      store.commit('setProject', {
          name: result[1].clientInfo.project.name,
          logo: result[1].clientInfo.project.logo,
          logo_square: result[1].clientInfo.project.logo_square,
          mp_qrcode: result[1].clientInfo.project.mp_qrcode
      })
      localStorage.setItem('project_logo_square_c', result[1].clientInfo.project.logo_square)
      localStorage.setItem('project_name_c', result[1].clientInfo.project.name)
      this.changeFavicon(result[1].clientInfo.project.logo_square)
      document.title = result[1].clientInfo.project.name
      return result[0]
  }

  changeFavicon (link: string) {
      const $favicon = document.querySelector('link[rel="icon"]') as HTMLAnchorElement
      if ($favicon !== null) {
          $favicon.href = link
      } else {
          const $newFavicon = document.createElement('link')
          $newFavicon.rel = 'icon'
          $newFavicon.href = link
          document.head.appendChild($newFavicon)
      }
  }
}

export default new User()
