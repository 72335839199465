
import { defineComponent } from 'vue'
import { RouteMeta, _RouteRecordBase } from 'vue-router'

export default defineComponent({
    name: 'Home',
    watch: {
        $route (to: _RouteRecordBase) {
            const name = to.name as string
            const meta = to.meta as RouteMeta
            if (meta.keepAlive && this.includeList.indexOf(name) === -1) {
                this.includeList.push(name)
                console.log(this.includeList)
            }
            console.log(this.includeList)
        }
    },
    data () {
        return {
            isNav: false,
            theme: {
                _text: 'red'
            },
            includeList: [] as string[]
        }
    }
})
