import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import 'element-plus/theme-chalk/index.css'
import user from '@/utils/user'
import global from '@/utils/global'
import util from '@/utils/util'
import noData from '@/components/no-data.vue'

const app = createApp(App)
app.use(store)
    .use(router)
    .use(Element, { locale })
    .mount('#app')
app.component('no-data', noData)
if (localStorage.tokenC) {
    global.token = localStorage.tokenC
    user.getUserInfo()
}
app.config.globalProperties.$util = util
// TODO 理赔详情
