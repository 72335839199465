
import { defineComponent } from 'vue'
import user from '@/utils/user'
import { getMessage, MessageDto, getUnReadMessage } from '@/api/message'
import $store from '@/store'
import SwitchClient from '@/views/index/components/switch-client.vue'

export default defineComponent({
    components: { SwitchClient },
    data () {
        return {
            companyName: '',
            isSwitchClientAlert: false,
            qrcode: '',
            messageList: [] as MessageDto[],
            unReadNum: 0,
            $store
        }
    },
    async created () {
        user.waitLogin().then(async () => {
            // 获取左边的公司名称
            this.companyName = user.companyName
            // 获取小程序码推荐小程序
            this.qrcode = user.miniprogQrcode
            const data = await getUnReadMessage()
            console.log(data, 'getUnReadMessage')
            this.unReadNum = data
        })
    },
    methods: {

        async getMessage () {
            const messageList = await getMessage(
                {
                    page: 1,
                    pageSize: 4
                },
                {
                    isLoading: false
                }
            )
            this.messageList = messageList.data
        },

        // 用户退出
        exit () {
            user.exit()
        },
        // 切换账户
        switchClient () {
            this.isSwitchClientAlert = !this.isSwitchClientAlert
        }
    }
})
